import React from 'react';

function Home() {
    return (
        <>
            <div className="page page-narrow">
                <div className="section-title">Start</div>
            </div>
        </>
    );
}

export default Home;
