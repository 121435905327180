import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { requestPasswordReset } from '../api';
import logo from '../logo.png';
import { useCommons } from '../CommonContext';

function ResetPasswordRequest() {
    const navigate = useNavigate();
    const { sendEvent } = useCommons();
    const [email, setEmail] = useState("");
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);

    const handleRequestReset = async (event) => {
        event.preventDefault();
        try {
            sendEvent('reset_password', 'requested', '');
            await requestPasswordReset({ email });
            setSuccessMessage("A password reset link has been sent to your email.");
        } catch (error) {
            console.error("Password reset request error", error);
            if (error.response && error.response.data && error.response.data.message) {
                setErrorMessage(error.response.data.message);
            } else {
                setErrorMessage("An unexpected error occurred.");
            }
        }
    };

    return (
        <div className="container-fluid welcome-container d-flex justify-content-center align-items-center">
            <div className="auth-box">
                <div className="welcome-logo">
                    <img src={logo} alt="Logo" />
                </div>
                <div className="card section-card">
                    <div className="card-body">
                        <h2 className="mb-3">Reset Password</h2>
                        {errorMessage && (
                            <div className="alert alert-danger" role="alert">
                                {errorMessage}
                            </div>
                        )}
                        {successMessage && (
                            <div className="alert alert-success" role="alert">
                                {successMessage}
                            </div>
                        )}
                        <form onSubmit={handleRequestReset} className="mb-3">
                            <div className="form-group mb-3">
                                <label>Email</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                    required
                                />
                            </div>
                            <button type="submit" className="btn btn-primary w-100">Send Reset Link</button>
                        </form>
                        <button className="btn btn-light w-100" onClick={() => navigate('/')}>Back to Login</button>
                    </div>
                </div>
                <div className="page-footer text-center">
                    <div className="footer-links">
                        <div><a href="https://www.tinyrebrand.com/terms-of-service" target="_blank" title="Tiny Rebrand Terms of Service">Terms of Service</a></div>
                        <div><a href="https://www.tinyrebrand.com/privacy-policy" target="_blank" title="Tiny Rebrand Privacy Policy">Privacy Policy</a></div>
                        <div>by <a href="https://x.com/xkrasauskas" hrefLang="en" target="_blank">@xkrasauskas</a></div>
                    </div>
                    <p>© 2024 All rights reserved.</p>
                </div>
            </div>
        </div>
    );
}

export default ResetPasswordRequest;
