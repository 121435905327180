import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signIn } from '../api';
import logo from '../logo.png';
import { useCommons } from '../CommonContext';
import LoginWithGoogleButton from './LoginWithGoogleButton';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function Login(props) {
    const navigate = useNavigate();
    const { setUser, setLimits, sendEvent, refreshFavoriteTags } = useCommons();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [twoFactorToken, setTwoFactorToken] = useState(null);
    const [showTwoFactorModal, setShowTwoFactorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const handleLogin = async (event) => {
        event.preventDefault();
        try {
            const response = await signIn({ email, password, token: twoFactorToken });

            if (response.data && response.data.twoFactor) {
                setShowTwoFactorModal(true);
            } else {
                const token = response.data.token;
                const user = response.data.user;
                const limits = response.data.limits;
                const currentTime = Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds()) / 1000;

                console.log(user);
                console.log(user.isVerified);
                
                if (user.isVerified === true) {
                    setUser(user);
                    setLimits(limits);
                    sendEvent('login', 'logged_in', '');
                    localStorage.setItem("jwtToken", token);

                    refreshFavoriteTags();

                    props.onLoginSuccess();
                } else {
                    props.onEmailVerification(email);
                }
            }
        } catch (error) {
            setErrorMessage(error.message);
        }
    };

    const handleTwoFactorModalClose = () => {
        setShowTwoFactorModal(false);
        setTwoFactorToken(null);
        setErrorMessage(null);
    }

    return (
        <>
            <div className="container-fluid welcome-container d-flex flex-column justify-content-center align-items-center">
                <div className="auth-box">
                    <div className="welcome-logo">
                        <img src={logo} alt="Logo" />
                    </div>
                    <div className="card section-card">
                        <div className="card-body">
                            <h2 className="mb-3">Login</h2>
                            {errorMessage && (
                                <div className="alert alert-danger" role="alert">
                                    {errorMessage}
                                </div>
                            )}
                            <form onSubmit={handleLogin} className="mb-3">
                                <div className="form-group mb-3">
                                    <label>Email</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <label>Password</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        value={password}
                                        onChange={e => setPassword(e.target.value)}
                                        required
                                    />
                                </div>
                                <button type="submit" className="btn btn-primary w-100">Login</button>
                            </form>
                            <div className="mb-3">
                                <LoginWithGoogleButton onLoginSuccess={props.onLoginSuccess} setErrorMessage={setErrorMessage} />
                            </div>
                            <button className="btn btn-light mb-3 w-100" onClick={() => navigate('/reset-password-request')}>Forgot Password?</button>
                            <button className="btn btn-light w-100" onClick={() => navigate('/register')}>Don`t have an Account? Sign up</button>
                        </div>
                    </div>
                    <div className="page-footer text-center">
                        <div className="footer-links">
                            <div><a href="https://www.tinyrebrand.com/terms-of-service" target="_blank" title="Tiny Rebrand Terms of Service">Terms of Service</a></div>
                            <div><a href="https://www.tinyrebrand.com/privacy-policy" target="_blank" title="Tiny Rebrand Privacy Policy">Privacy Policy</a></div>
                            <div>Made by <a href="https://vclck.com/my-twitter?utm_source=tinyrebrand-app" hrefLang="en" target="_blank">@xkrasauskas</a></div>
                        </div>
                        <p>© 2024 All rights reserved. From 🇪🇺 with ♥️.</p>
                    </div>
                </div>
            </div>
            <Modal show={showTwoFactorModal} onHide={() => setShowTwoFactorModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Two-Factor Authentication</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {errorMessage && (
                        <div className="alert alert-danger" role="alert">
                            {errorMessage}
                        </div>
                    )}
                    <div className="form-group mb-3">
                        <label>2FA Token</label>
                        <input
                            type="text"
                            className="form-control"
                            value={twoFactorToken}
                            onChange={e => setTwoFactorToken(e.target.value)}
                            required
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleTwoFactorModalClose}>Close</Button>
                    <Button variant="primary" onClick={handleLogin}>Submit</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Login;
