import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import Login from './components/Login';
import Register from './components/Register';
import Dashboard from './components/Dashboard';
import EmailVerification from './components/EmailVerification';
import ResetPassword from './components/ResetPassword';
import ResetPasswordRequest from './components/ResetPasswordRequest';
import AccountDeleted from './components/AccountDeleted';
import { refreshToken, resendVerificationEmail } from './api';
import { ToastProvider } from './ToastContext';
import jwtDecode from 'jwt-decode';
import CookieConsentBanner from './CookieConsentBanner';
import { CommonsProvider } from './CommonContext';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import 'bootstrap/dist/js/bootstrap.bundle.min'; // Import Bootstrap JS (includes Popper.js)
import './App.css';

function AppContent() {
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [emailVerification, setEmailVerification] = useState(null);

    const handleLogout = () => {
        setIsLoggedIn(false);
        localStorage.removeItem("jwtToken");
    };

    const handleEmailVerification = (email) => {
        setEmailVerification(email);
        navigate('/verify-email');
    };

    const handleResendEmail = async () => {
        if (emailVerification) {
            await resendVerificationEmail(emailVerification);
        }
    };

    useEffect(() => {
        const storedToken = localStorage.getItem('jwtToken');

        if (storedToken && storedToken !== 'undefined') {
            const decodedToken = jwtDecode(storedToken);
            const currentTime = Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds()) / 1000;

            if (decodedToken && decodedToken.exp > currentTime) {
                setIsLoggedIn(true);
            } else {
                localStorage.removeItem('jwtToken');
                setIsLoggedIn(false);
                console.warn('Token has expired');
            }
        }

        const intervalId = setInterval(async () => {
            try {
                await refreshToken();
                console.log('Token refreshed');
            } catch (error) {
                console.error('Failed to refresh token', error);
            }
        }, 30 * 60 * 1000); // 30 minutes

        return () => {
            clearInterval(intervalId); // Clear the interval when the component is unmounted
        };
    }, []);

    return (
        <>
            {!isLoggedIn ? (
                <Routes>
                    <Route path="/" element={
                        <Login
                            onLoginSuccess={() => setIsLoggedIn(true)}
                            onEmailVerification={handleEmailVerification}
                        />
                    } />
                    <Route path="/reset-password-request" element={<ResetPasswordRequest />} />
                    <Route path="/register" element={
                        <Register
                            onEmailVerification={handleEmailVerification}
                        />
                    } />
                    <Route path="/verify-email" element={
                        <EmailVerification
                            email={emailVerification}
                            onResendEmail={handleResendEmail}
                        />
                    } />
                    <Route path="/reset-password" element={<ResetPassword />} />
                    <Route path="/account-deleted" element={<AccountDeleted />} />
                </Routes>
            ) : (
                <Dashboard onLogout={handleLogout} />
            )}
        </>
    );
}

function App() {
    return (
        <ToastProvider>
            <CommonsProvider>
                <Router>
                    <div className="App">
                        <AppContent />
                    </div>
                    <CookieConsentBanner />
                </Router>
            </CommonsProvider>
        </ToastProvider>
    );
}

export default App;
