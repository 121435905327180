import React from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { signInWithGoogle } from '../api';
import { useCommons } from '../CommonContext';

const GOOGLE_OAUTH_CLIENT_ID = process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID;

const LoginWithGoogleButton = (props) => {
    const { setUser, sendEvent } = useCommons();

    const handleLoginSuccess = async (response) => {
        console.log(response);
        
        try {
            const userResponse = await signInWithGoogle(response.credential);
            const token = userResponse.data.token;
            const user = userResponse.data.user;

            setUser(user);
            sendEvent('login', 'logged_in_google', '');
            localStorage.setItem("jwtToken", token);

            props.onLoginSuccess();
        }
        catch (error) {
            console.error("Login error", error);
            if (error.response && error.response.data && error.response.data.message) {
                props.setErrorMessage(error.response.data.message);
            } else {
                props.setErrorMessage("An unexpected error occurred.");
            }
        }
    };

    const handleLoginFailure = (error) => {
        console.error(error);
    };

    return (
        <GoogleOAuthProvider clientId={GOOGLE_OAUTH_CLIENT_ID}>
            <GoogleLogin
                onSuccess={handleLoginSuccess}
                onError={handleLoginFailure}
            />
        </GoogleOAuthProvider>
    );
};

export default LoginWithGoogleButton;
