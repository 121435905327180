import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

function buildQueryString(params = {}) {
    const queryParts = [];

    Object.keys(params).forEach(key => {
        if (params[key] !== undefined && params[key] !== null && params[key] !== '') {
            queryParts.push(`${key}=${encodeURIComponent(params[key])}`);
        }
    });

    return queryParts.length ? `?${queryParts.join('&')}` : '';
}

const getAuthConfig = (endpoint, method = 'GET', data = null) => {
    const jwtToken = localStorage.getItem('jwtToken');
    return {
        url: `${endpoint}`,
        method,
        headers: {
            'Authorization': `Bearer ${jwtToken}`,
            'Content-Type': 'application/json'
        },
        data
    };
};

const request = async (endpoint, method = 'GET', data = null) => {
    const config = getAuthConfig(endpoint, method, data);

    try {
        const response = await axios(config);
        return response.data;
    } catch (error) {
        if (error.response) {
            throw new Error(error.response.data.message || 'An error occurred');
        } else if (error.request) {
            throw new Error('No response received from server');
        } else {
            throw new Error('Error setting up the request');
        }
    }
};

const fetchWithQuery = (baseEndpoint, queryParams = {}, method = 'GET', data = null) => {
    const queryString = buildQueryString(queryParams);
    return request(`${baseEndpoint}${queryString}`, method, data);
};

/**
 * Domains
 */
export const addDomain = async (data) => fetchWithQuery(`/domains`, {}, 'POST', data);
export const changeDomain = async (id, data) => fetchWithQuery(`/domains/${id}`, {}, 'PUT', data);
export const deleteDomain = async (id, data) => fetchWithQuery(`/domains/${id}`, {}, 'DELETE', data);
export const fetchDomainOfId = async (id) => fetchWithQuery(`/domains/${id}`);
export const fetchDomains = async ({ q = '', start = 0, limit = null } = {}) => fetchWithQuery(`/domains`, { q, start, limit });
export const fetchAvailableDomains = async () => fetchWithQuery(`/available-domains`);
export const verifyDomain = async (id) => fetchWithQuery(`/domains/${id}/verify`, {}, 'POST');

/**
 * Links
 */
export const addLink = async (data) => fetchWithQuery(`/links`, {}, 'POST', data);
export const changeLink = async (id, data) => fetchWithQuery(`/links/${id}`, {}, 'PUT', data);
export const deleteLink = async (id, data) => fetchWithQuery(`/links/${id}`, {}, 'DELETE', data);
export const fetchLinkOfId = async (id) => fetchWithQuery(`/links/${id}`);
export const fetchLinks = async ({ tagId = '', q = '', start = 0, limit = null } = {}) => fetchWithQuery(`/links`, { tagId, q, start, limit });
export const uploadLinkImage = async (id, data) => fetchWithQuery(`/links/${id}/image`, {}, 'POST', data);

/**
 * Tags
 */
export const addTag = async (data) => fetchWithQuery(`/tags`, {}, 'POST', data);
export const changeTag = async (id, data) => fetchWithQuery(`/tags/${id}`, {}, 'PUT', data);
export const deleteTag = async (id, data) => fetchWithQuery(`/tags/${id}`, {}, 'DELETE', data);
export const fetchTagOfId = async (id) => fetchWithQuery(`/tags/${id}`);
export const fetchTags = async ({ q = '', start = 0, limit = null } = {}) => fetchWithQuery(`/tags`, { q, start, limit });
export const fetchFavoriteTags = async () => fetchWithQuery(`/tags/favorite`);

/**
 * Stats
 */
export const fetchStats = async ({ startDate = '', endDate = '', q = '' } = {}) => fetchWithQuery(`/stats`, { startDate, endDate, q });

/**
 * Subscription
 */
export const fetchCurrentSubscription = async () => fetchWithQuery(`/subscription/current`, {}, 'GET');
export const createCheckoutSession = async (planId, billingCycle) => fetchWithQuery(`/subscription/checkout`, {}, 'POST', { planId, billingCycle });
export const verifyCheckoutSession = async (sessionId) => fetchWithQuery(`/subscription/verify`, {}, 'POST', { sessionId });

/**
 * User
 */
export const changeUserEmail = async (data) => fetchWithQuery(`/users/email`, {}, 'PUT', data);
export const changeUserPassword = async (data) => fetchWithQuery(`/users/password`, {}, 'PUT', data);
export const deleteUser = async ({ password }) => fetchWithQuery(`/users/me`, {}, 'DELETE', { password });
export const fetchUser = async () => fetchWithQuery(`/users/me`);
export const fetchUserLimits = async () => fetchWithQuery(`/users/me/limits`);
export const requestEnableTwoFactor = async () => fetchWithQuery(`/users/me/enable-twofactor`, {}, 'GET');
export const enableTwoFactor = async ({ token }) => fetchWithQuery(`/users/me/enable-twofactor`, {}, 'POST', { token });
export const disableTwoFactor = async ({ token }) => fetchWithQuery(`/users/me/disable-twofactor`, {}, 'POST', { token });
export const signIn = async (credentials) => fetchWithQuery(`/signin`, {}, 'POST', credentials);
export const signInWithGoogle = async (token) => fetchWithQuery(`/signin/google`, {}, 'POST', { token });
export const signUp = async (credentials) => fetchWithQuery(`/signup`, {}, 'POST', credentials);
export const resendVerificationEmail = async (email) => fetchWithQuery(`/resend-verify-email`, {}, 'POST', { email });
export const resetPassword = async ({ userId, tokenId, token, newPassword }) => fetchWithQuery(`/reset-password`, {}, 'POST', { userId, tokenId, token, newPassword });
export const requestPasswordReset = async ({ email }) => fetchWithQuery(`/request-password-reset`, {}, 'POST', { email });
export const refreshToken = async () => {
    try {
        const response = await fetchWithQuery('/users/refresh-token');

        localStorage.setItem('jwtToken', response.data.token);
    } catch (error) {
        console.error('Error refreshing token', error.message);
        throw error;
    }
}
