import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../logo.png';

function EmailVerification({ email, onResendEmail }) {
    const navigate = useNavigate();
    const [timer, setTimer] = useState(60);
    const [canResend, setCanResend] = useState(false);

    useEffect(() => {
        if (timer > 0) {
            const intervalId = setInterval(() => {
                setTimer(prev => prev - 1);
            }, 1000);
            return () => clearInterval(intervalId);
        } else {
            setCanResend(true);
        }
    }, [timer]);

    const handleResendEmail = () => {
        setTimer(60);
        setCanResend(false);
        onResendEmail();
    };

    return (
        <div className="container-fluid welcome-container d-flex justify-content-center align-items-center">
            <div className="auth-box">
                <div className="welcome-logo">
                    <img src={logo} alt="Logo" />
                </div>
                <div className="card section-card">
                    <div className="card-body">
                        <h2 className="mb-3">Email Verification</h2>
                        <p>A verification email has been sent to {email}. Please check your inbox and click the verification link.</p>
                        <button
                            className="btn btn-primary mb-3 w-100"
                            onClick={handleResendEmail}
                            disabled={!canResend}
                        >
                            Resend Email {canResend ? '' : `(${timer}s)`}
                        </button>
                        <button className="btn btn-light w-100" onClick={() => navigate('/')}>Back to Login</button>
                    </div>
                </div>
                <div className="page-footer text-center">
                    <div className="footer-links">
                        <div><a href="https://www.tinyrebrand.com/terms-of-service" target="_blank" title="Tiny Rebrand Terms of Service">Terms of Service</a></div>
                        <div><a href="https://www.tinyrebrand.com/privacy-policy" target="_blank" title="Tiny Rebrand Privacy Policy">Privacy Policy</a></div>
                        <div>by <a href="https://x.com/xkrasauskas" hrefLang="en" target="_blank">@xkrasauskas</a></div>
                    </div>
                    <p>© 2024 All rights reserved.</p>
                </div>
            </div>
        </div>
    );
}

export default EmailVerification;
