import React, { useState, useEffect } from 'react';
import { fetchCurrentSubscription, createCheckoutSession, verifyCheckoutSession } from '../api';
import Button from 'react-bootstrap/Button';
import { useToast } from '../ToastContext';
import { useCommons } from '../CommonContext';
import { useLocation } from 'react-router-dom';
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';

function Subscription() {
    const { setLoading, sendEvent, limits } = useCommons();
    const { showErrorToast } = useToast();
    const [subscription, setSubscription] = useState({});
    const [subscriptionManagementUrl, setSubscriptionManagementUrl] = useState(null);
    const [billingCycle, setBillingCycle] = useState('monthly');
    const location = useLocation();

    const refreshSubscriptionData = async () => {
        setLoading(true);
        try {
            const response = await fetchCurrentSubscription();
            setSubscription(response.data);
            if (response.meta.subscriptionManagementUrl) {
                setSubscriptionManagementUrl(response.meta.subscriptionManagementUrl);
            }
        } catch (error) {
            showErrorToast('Error', error.message);
        } finally {
            setLoading(false);
        }
    };

    const verifyCheckout = async (checkoutId) => {
        setLoading(true);
        try {
            const response = await verifyCheckoutSession(checkoutId);
            sendEvent('subscription', 'finished', '');
            if (response.data.success) {
                await refreshSubscriptionData();
                sendEvent('subscription', 'successful', '');
            }
        } catch (error) {
            showErrorToast('Error', error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const checkoutId = query.get('checkout');
        if (checkoutId) {
            verifyCheckout(checkoutId);
        } else {
            refreshSubscriptionData();
        }
    }, [location.search]);

    const handleSubscribe = async (planId) => {
        setLoading(true);
        try {
            sendEvent('Subscription', 'Started', planId);
            const response = await createCheckoutSession(planId, billingCycle);
            window.location.href = response.data.url;
        } catch (error) {
            showErrorToast('Error', error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="page page-narrow subscription">
            <h1 className="section-title">Subscription</h1>
            <div className="card section-card">
                <div className="card-body">
                    <div className="mb-3">
                        {subscription.isActive ? (
                            <Badge bg="success">Active</Badge>
                        ) : (
                            <Badge bg="danger">Expired</Badge>
                        )}
                    </div>
                    <div className="fw-bold mb-2">Current Plan: {subscription.planName}</div>
                    {subscription.isTrial ? (
                        <>
                            <div className="mb-3">Free trial ends on: {subscription.trialEndsAtBeautified}</div>
                            <Form.Select
                                className="mb-3"
                                value={billingCycle}
                                onChange={(e) => setBillingCycle(e.target.value)}
                            >
                                <option value="monthly">Monthly</option>
                                <option value="yearly">Yearly</option>
                            </Form.Select>
                            <Button
                                variant="primary"
                                onClick={() => handleSubscribe('pro')}
                            >
                                Subscribe to Pro ({billingCycle})
                            </Button>
                        </>
                    ) : (
                        <>
                            <div className="mb-2">Subscribed on: {subscription.startAtBeautified}</div>
                            <div className="mb-3">Subscription ends on: {subscription.endAtBeautified}</div>
                        </>
                    )}
                    {subscriptionManagementUrl && (
                        <Button
                            variant="secondary"
                            href={subscriptionManagementUrl}
                            target="_blank"
                        >
                            Manage Subscription
                        </Button>
                    )}
                </div>
            </div>
            <div className="card section-card">
                <div className="card-body">
                    <div className="card-title">Limits</div>
                    {limits && (
                        <>
                            <div className="mb-3">
                                <div className="d-flex justify-content-between mb-1">
                                    <span>Links:</span>
                                    <span>{limits.current_links} / {limits.max_links}</span>
                                </div>
                                <div className="progress">
                                    <div
                                        className="progress-bar"
                                        role="progressbar"
                                        style={{ width: `${(limits.current_links / limits.max_links) * 100}%` }}
                                        aria-valuenow={limits.current_links}
                                        aria-valuemin="0"
                                        aria-valuemax={limits.max_links}
                                    ></div>
                                </div>
                            </div>
                            <div className="mb-3">
                                <div className="d-flex justify-content-between mb-1">
                                    <span>Tags:</span>
                                    <span>{limits.current_tags} / {limits.max_tags}</span>
                                </div>
                                <div className="progress">
                                    <div
                                        className="progress-bar"
                                        role="progressbar"
                                        style={{ width: `${(limits.current_tags / limits.max_tags) * 100}%` }}
                                        aria-valuenow={limits.current_tags}
                                        aria-valuemin="0"
                                        aria-valuemax={limits.max_tags}
                                    ></div>
                                </div>
                            </div>
                            <div className="mb-3">
                                <div className="d-flex justify-content-between mb-1">
                                    <span>Domains:</span>
                                    <span>{limits.current_domains} / {limits.max_domains}</span>
                                </div>
                                <div className="progress">
                                    <div
                                        className="progress-bar"
                                        role="progressbar"
                                        style={{ width: `${(limits.current_domains / limits.max_domains) * 100}%` }}
                                        aria-valuenow={limits.current_domains}
                                        aria-valuemin="0"
                                        aria-valuemax={limits.max_domains}
                                    ></div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Subscription;
