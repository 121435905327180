import React, { useState, useEffect } from 'react';
import { fetchLinks, fetchAvailableDomains, fetchTags, deleteLink } from '../api';
import Button from 'react-bootstrap/Button';
import AddLinkModal from './AddLinkModal';
import EditLinkModal from './EditLinkModal';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import { useToast } from '../ToastContext';
import { useCommons } from '../CommonContext';
import Form from 'react-bootstrap/Form';
import Pagination from 'react-bootstrap/Pagination';
import Badge from 'react-bootstrap/Badge';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

function Links() {
    const { tagId } = useParams();
    const { setLoading, refreshLimits, limits } = useCommons();
    const { showErrorToast, showSuccessToast } = useToast();
    const [links, setLinks] = useState([]);
    const [domains, setDomains] = useState([]);
    const [tags, setTags] = useState([]);
    const [showAddLinkModal, setShowAddLinkModal] = useState(false);
    const [showEditLinkModal, setShowEditLinkModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedLinkId, setSelectedLinkId] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalLinks, setTotalLinks] = useState(0);
    const itemsPerPage = 10;

    const refreshList = async () => {
        setLoading(true);
        try {
            const response = await fetchLinks({ tagId: tagId, q: searchQuery, start: (currentPage - 1) * itemsPerPage, limit: itemsPerPage });
            setLinks(response.data ?? []);
            setTotalLinks(response.meta.total ?? 0);
        } catch (error) {
            showErrorToast('Error', error.message);
        } finally {
            setLoading(false);
        }
    };

    const refreshDomainsAndTags = async () => {
        setLoading(true);
        try {
            const [domainsResponse, tagsResponse] = await Promise.all([fetchAvailableDomains(), fetchTags()]);
            setDomains(domainsResponse.data ?? []);
            setTags(tagsResponse.data ?? []);
        } catch (error) {
            showErrorToast('Error', error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        refreshList();
    }, [tagId]);

    useEffect(() => {
        refreshDomainsAndTags();
        refreshList();
    }, [currentPage, searchQuery]);

    const handleDeleteClick = (id) => {
        setSelectedLinkId(id);
        setShowDeleteModal(true);
        setShowEditLinkModal(false);
    };

    const handleDeleteConfirm = async () => {
        setLoading(true);
        try {
            await deleteLink(selectedLinkId);
            showSuccessToast('Success', 'Link deleted successfully');
            refreshList();
            refreshLimits();
        } catch (error) {
            showErrorToast('Error', error.message);
        } finally {
            setShowDeleteModal(false);
            setShowEditLinkModal(false);
            setSelectedLinkId(null);
            setLoading(false);
        }
    };

    const handleDeleteCancel = () => {
        setShowDeleteModal(false);
        setShowEditLinkModal(true);
    };

    const handleEditClick = (link) => {
        setSelectedLinkId(link.id);
        setShowEditLinkModal(true);
    };

    const handleSearchKeyPress = (e) => {
        if (e.key === 'Escape') {
            setSearchQuery('');
        }
    };

    const handleCopyLink = (link) => {
        navigator.clipboard.writeText(link).then(() => {
            showSuccessToast('Copied', 'Link copied to clipboard');
        }).catch(err => {
            showErrorToast('Error', 'Failed to copy link');
        });
    };

    const filteredLinks = links.filter(link =>
        link.title.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const totalPages = Math.ceil(totalLinks / itemsPerPage);

    return (
        <>
            <div className="page page-narrow links">
                <div className="section-title">Links</div>
                <div className="card section-card">
                    <div className="card-body">
                        <Button variant="primary" onClick={() => setShowAddLinkModal(true)} className="mb-3">Add Link</Button>
                        <Form.Control
                            type="text"
                            placeholder="Search links..."
                            className="mb-3"
                            value={searchQuery}
                            onChange={e => setSearchQuery(e.target.value)}
                            onKeyDown={handleSearchKeyPress}
                        />
                        {filteredLinks.length > 0 ? (
                            <>
                                <div className="data-list mb-3">
                                    {filteredLinks.map(link => {
                                        const domain = domains.find(d => d.id === link.domainId);
                                        const fullLink = domain ? `https://${domain.name}/${link.slug}` : `/${link.slug}`;
                                        return (
                                            <div key={link.id} className="data-list-item clickable" onClick={() => handleEditClick(link)}>
                                                <div className="row align-items-start">
                                                    <div className="col">
                                                        <span>{link.title}</span>
                                                    </div>
                                                    <div className="col d-flex justify-content-end">
                                                        <div>
                                                            <Badge bg={link.status ? "success" : "secondary"} className="me-2">{link.status ? "Active" : "Inactive"}</Badge>
                                                            {link.hasPassword === 1 && <Badge bg="warning" className="me-2">Password Protected</Badge>}
                                                        </div>
                                                        <div>
                                                            {link.trs && <Badge bg="info" className="me-2">TR S</Badge>}
                                                            {link.trm && <Badge bg="info" className="me-2">TR M</Badge>}
                                                            {link.trc && <Badge bg="info" className="me-2">TR C</Badge>}
                                                        </div>
                                                        <div>
                                                            {link.tags && Object.keys(link.tags).length > 0 ? Object.values(link.tags).map(tag => (
                                                                <Badge bg="secondary" key={tag.id} className="me-1">{tag.name}</Badge>
                                                            )) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <OverlayTrigger
                                                        placement="bottom"
                                                        overlay={<Tooltip>{link.url}</Tooltip>}
                                                    >
                                                        <a href={fullLink} target="_blank" rel="noopener noreferrer" onClick={(e) => { e.preventDefault(); handleCopyLink(fullLink); }}>
                                                            {fullLink}
                                                        </a>
                                                    </OverlayTrigger>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                                <Pagination>
                                    <Pagination.Prev onClick={() => setCurrentPage(Math.max(currentPage - 1, 1))} disabled={currentPage === 1} />
                                    <Pagination.Item>{currentPage}</Pagination.Item>
                                    <Pagination.Next onClick={() => setCurrentPage(Math.min(currentPage + 1, totalPages))} disabled={currentPage === totalPages} />
                                </Pagination>
                            </>
                        ) : <p>No links available.</p>}
                        <div className="data-list-limits">Links: {limits ? limits.current_links : null} / {limits ? limits.max_links : null}</div>
                    </div>
                </div>
            </div>
            <AddLinkModal
                showModal={showAddLinkModal}
                onCancel={() => setShowAddLinkModal(false)}
                refreshList={refreshList}
                domains={domains}
                tags={tags}
            />
            <EditLinkModal
                showModal={showEditLinkModal}
                onCancel={() => setShowEditLinkModal(false)}
                onDelete={handleDeleteClick}
                selectedId={selectedLinkId}
                refreshList={refreshList}
                domains={domains}
                tags={tags}
            />
            <DeleteConfirmationModal
                showModal={showDeleteModal}
                onCancel={handleDeleteCancel}
                onConfirm={handleDeleteConfirm}
                itemLabel="link"
            />
        </>
    );
}

export default Links;
