import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { verifyDomain } from '../api';
import { useToast } from '../ToastContext';
import { useCommons } from '../CommonContext';
import Badge from 'react-bootstrap/Badge';

function VerifyDomainModal({ showModal, onCancel, onDelete, selectedId, domain, refreshList }) {
    const { loading, setLoading } = useCommons();
    const { showErrorToast, showSuccessToast } = useToast();
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        setErrorMessage('');
    }, [domain]);

    const handleVerify = async () => {
        setLoading(true);
        try {
            const response = await verifyDomain(domain.id);
            if (response.data.isVerified) {
                showSuccessToast('Success', 'Domain verified successfully');
                onCancel();
                refreshList();
            } else {
                setErrorMessage(response.data.message || 'Verification failed.');
            }
        } catch (error) {
            showErrorToast('Error', error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            {domain ? (
                <Modal show={showModal} onHide={onCancel} backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title>Verify Domain</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {errorMessage && (
                            <div className="alert alert-danger" role="alert">
                                {errorMessage}
                            </div>
                        )}
                        <div className="mb-3">
                            {domain.isVerified ? <Badge bg="success" className="me-2">Verified</Badge> : <Badge bg="secondary" className="me-2">Unverified</Badge>}
                            {domain.isAVerified ? <Badge bg="success" className="me-2">A</Badge> : <Badge bg="secondary" className="me-2">A</Badge>}
                            {domain.isPublic ? <Badge bg="secondary" className="me-2">Public</Badge> : null}
                        </div>
                        <p><strong>Domain:</strong> {domain.name}</p>
                        <p><strong>1.</strong> Add the following DNS TXT record to your domain at the root level (@):</p>
                        <p><code>{`TXT @ "tiny-rebrand-key=${domain.verificationToken}"`}</code></p>
                        <p><strong>2.</strong> Add the following A record to your domain:</p>
                        <p><code>{`A @ 185.81.165.135`}</code></p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={() => onDelete(selectedId)} className="me-auto">Delete</Button>
                        <Button variant="secondary" onClick={onCancel}>Close</Button>
                        <Button variant="primary" onClick={handleVerify} disabled={loading}>
                            {loading ? 'Verifying...' : 'Verify'}
                        </Button>
                    </Modal.Footer>
                </Modal>) : null}
        </>
    );
}

export default VerifyDomainModal;
