export const slugGenerator = (text) => {
    const slug = text.toLowerCase()
    //const transliteratedSlug = slug.split('').map(char => characterReplacements[char] || char).join('');
    const cleanedSlug = slug
        .replace(/[^\w\s-]/g, '') // Remove remaining symbols
        .replace(/\s+/g, '-') // Replace spaces with "-"
        .replace(/[-]+/g, '-') // Replace multiple hyphens with a single hyphen
        .trim(); // Trim leading/trailing hyphens

    return cleanedSlug;
};
